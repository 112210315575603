import React from 'react';
import { Router } from 'react-router-dom';
import * as T from './types';
import { useRootContext } from '../contexts/Root';
import Renderer from '../components/Renderer';
import { AppFC } from '../types/customReactTypes';

const App: AppFC<T.RootProps> = (props) => {
  const { navigation } = useRootContext();

  return (
    <Router history={navigation}>
      <Renderer {...props} />
    </Router>
  );
};

export default App;
